var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('hr',{staticClass:"hr-text",staticStyle:{"font-weight":"600"},attrs:{"data-content":"Kho trung gian"}}),(_vm.editable)?_c('b-row',[_c('b-col',[_c('b-button',{staticClass:"mb-2",attrs:{"size":"sm","variant":"warning"},on:{"click":_vm.addRow}},[_c('i',{staticClass:"fas fa-plus",staticStyle:{"font-size":"1rem"}}),_vm._v(" Thêm mới ")])],1)],1):_vm._e(),(_vm.renderComponent)?_c('b-table',{attrs:{"bordered":"","hover":"","fields":_vm.fields,"items":_vm.items},scopedSlots:_vm._u([{key:"cell(storeName)",fn:function(row){return [(_vm.editable)?_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptionStores,"limit":10,"input-props":{
          class: 'autosuggest__input',
          placeholder: 'Chọn kho',
          style: 'border-radius:0px!important',
        }},on:{"selected":function($event){return _vm.onSelectedStore($event, row.item)},"input":_vm.onInputChangeStore},scopedSlots:_vm._u([{key:"default",fn:function({ suggestion }){return [_c('div',[_vm._v(_vm._s(suggestion.item.name))])]}}],null,true),model:{value:(row.item.storeName),callback:function ($$v) {_vm.$set(row.item, "storeName", $$v)},expression:"row.item.storeName"}}):_c('span',[_vm._v(_vm._s(row.item.storeName))])]}},{key:"cell(inChargeEmployeeName)",fn:function(row){return [(_vm.editable)?_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptionsEmployee,"limit":10,"input-props":{
          class: 'autosuggest__input',
          placeholder: 'Chọn NV phụ trách',
          style: 'border-radius:0px!important',
        }},on:{"selected":function($event){return _vm.onSelectedEmployee($event, row.item)},"input":_vm.onInputChangeEmployee},scopedSlots:_vm._u([{key:"default",fn:function({ suggestion }){return [_c('div',[_vm._v(_vm._s(suggestion.item.name))])]}}],null,true),model:{value:(row.item.inChargeEmployeeName),callback:function ($$v) {_vm.$set(row.item, "inChargeEmployeeName", $$v)},expression:"row.item.inChargeEmployeeName"}}):_c('span',[_vm._v(_vm._s(row.item.inChargeEmployeeName))])]}},{key:"cell(actions)",fn:function(row){return [(_vm.editable)?_c('v-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"text-danger text-center",attrs:{"small":"","title":"Xóa"},on:{"click":function($event){return _vm.deleteItemInArray(row.item.count)}}},[_vm._v("mdi-delete")]):_vm._e()]}}],null,false,917102749)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }